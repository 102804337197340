import AsyncStorage from '@react-native-async-storage/async-storage';
var Url = "http://stocpdv.com.br:5555";
//var Url = "http://localhost:3000";
export default {
  Produtos: async (IdEmp, setCat, setDadoEmp, setPedidoList) => {
    console.log("IdEmp " + IdEmp);

    try {
      const responseProd = await fetch(Url + '/produtos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          // Se precisar de autorização, por exemplo, para um token JWT:
          // 'Authorization': `Bearer ${your_token}`,
          'Access-Control-Allow-Origin': '*',  // Netlify precisa dessa configuração se a API estiver hospedada em um domínio diferente.
        },
        body: JSON.stringify({ IdEmp: IdEmp }),
      });
      const dataProd = await responseProd.json();
      console.log("Entrou Dados");
      console.log(dataProd);
      setCat(dataProd);
    } catch (error) {
      console.log('Erro ao buscar dados:', error);
      console.error('Erro ao buscar dados:', error);
    }

    try {
      const responseEmp = await fetch(Url + '/dadoEmpresa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ IdEmp: IdEmp }),
      });
      const dataEmp = await responseEmp.json();
      setDadoEmp(dataEmp[0]);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }

    var tel = await AsyncStorage.getItem('Tel');
    // Código comentado para VerList
  },

  Finalizando: async (ValorEnt, Emp, Itens, Tel, Nome, Rua, Numero, Bairro, Complemento, Cidade, Estado, Pix, CartDebi, CartCred, Cheque, Boleto, Dinheiro, Troco, Buscar, Entreg, Consumo, PegarProdutos, setMesgSucess, setItens) => {
    var TransPix = Pix ? 1 : 0;
    var TransCartDeb = CartDebi ? 1 : 0;
    var TransCartCred = CartCred ? 1 : 0;
    var TransCheque = Cheque ? 1 : 0;
    var TransBoleto = Boleto ? 1 : 0;
    var TransDinheiro = Dinheiro ? 1 : 0;
    var TransBuscar = Buscar ? 1 : 0;
    var TransEntreg = Entreg ? 1 : 0;
    var TransConsumo = Consumo ? 1 : 0;

    try {
      const responsePedido = await fetch(Url + '/Pedido', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          IdEmp: Emp,
          Pedido: Itens,
          Tel: Tel,
          Nome: Nome,
          Rua: Rua,
          Numero: Numero,
          Complemento: Complemento,
          Bairro: Bairro,
          Cidade: Cidade,
          Estado: Estado,
          Pix: TransPix,
          CartCred: TransCartCred,
          CartDebi: TransCartDeb,
          Boleto: TransBoleto,
          Cheque: TransCheque,
          Dinheiro: TransDinheiro,
          Troco: Troco,
          Buscar: TransBuscar,
          Entreg: TransEntreg,
          Consumo: TransConsumo,
          ValorEnt: ValorEnt,
        }),
      });
      const dataPedido = await responsePedido.json();
      // PegarProdutos();
      setItens([]);
      setMesgSucess("Compra Realizada Com Sucesso!");
      console.log(dataPedido);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  },
};
